@import 'theme';
@import '~bootstrap/scss/bootstrap';

@mixin badge-outline {
  @each $element, $color in $theme-colors {
    .badge-outline-#{$element} {
      color: $color;
      border: 1px solid $color;
    }
  }
}

@include badge-outline;

div.readonly-field {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.wrapped {
    white-space: pre-line;
  }
  &[disabled] {
    background-color: #e6e6e6;
    opacity: 1;
  }
}

.text-warning {
  color: #eca200 !important;
}

.white-space-break-spaces {
  white-space: break-spaces;
}
.white-space-normal {
  white-space: normal !important;
}

.word-break-break-all {
  word-break: break-all;
}

.products-search-box {
  .search-box {
    .form-control {
      &::-webkit-input-placeholder {
        font-size: 12px;
      }
    }
  }
}

// Login Button
.login-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: $white;
}
.login-icon {
  position: absolute;
  &.google {
    width: 18px;
    height: 18px;
    margin-top: 11px;
    margin-left: 11px;
  }
  &.ic2 {
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-left: -10px;
  }
}
.btn-login {
  margin: 0px 0px 0 40px;
  color: $white;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-align: center;
  padding-top: 10px;
}
.btn-ic2 {
  width: 230px;
  height: 42px;
  background-color: #4f4f4f;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  &:hover,
  &:focus {
    box-shadow: 0 0 6px #4f4f4f;
    cursor: pointer;
  }
  &:active {
    background: #333333;
  }
}

.text-transform-none {
  text-transform: none !important;
}

.text-decoration-strikethrough {
  text-decoration: line-through !important;
  &:before,
  &:after {
    content: '\00a0\00a0';
  }
}

.slim-vertical-scroll-bar {
  overflow-y: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 8px 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.font-weight-600 {
  font-weight: 600 !important;
}
