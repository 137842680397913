$white: #fff !default;
$gray-100: #fafafa;
$gray-200: #e6e6e6;
$gray-300: #d9d9d9;
$gray-500: #828282;

// Themes
$theme-colors: (
  'primary': #ffb81c,
  'secondary': #2196f3,
  'success': #28a745,
  'info': #17a2b8,
  'danger': #e57373,
  'dark': $gray-500,
  'light': #fafafa,
  'lightDark': rgba(0, 0, 0, 0.1),
  'disabled': #bcbcbc,
  'black': #191919,
  'white': rgba(255, 255, 255, 0.7),
  'inputBorder': #ccc,
  'turquoise': #00c4a4,
  'cyan': #00cfd9,
);
$body-bg: map-get($theme-colors, light);

// Fonts
$font-family-sans-serif: Helvetica, Arial, sans-serif;
$headings-font-family: Helvetica, Arial, sans-serif;
$headings-font-weight: 700;
$small-font-size: 65%;

// Buttons
$btn-font-family: Helvetica, Arial, sans-serif;
$btn-font-size: 14px;
$btn-font-weight: 600;
$btn-border-radius: 5px;
$btn-padding-y: 0.5rem;
$btn-padding-x: 0.75rem;
$close-font-weight: 400;

// Cards
$card-border-radius: 8px;
